/*! Generated by Fontspring (https://www.fontspring.com) on November 9, 2017
*
* Fully installable fonts can be purchased at http://www.fontspring.com
*
* The fonts included in this stylesheet are subject to the End User License you purchased
* from Fontspring. The fonts are protected under domestic and international trademark and
* copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
* distributing this font software.
*
* (c) 2010-2017 Fontspring
*
*
*/

@font-face {
    font-family: 'sofia_pro';
    src: url('/fonts/sofiapro-light-webfont.woff2') format('woff2'),
        url('/fonts/sofiapro-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'sofia_pro';
    src: url('/fonts/sofiapro-lightit-webfont.woff2') format('woff2'),
        url('/fonts/sofiapro-lightit-webfont.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'sofia_pro';
    src: url('/fonts/sofiapro-regular-webfont.woff2') format('woff2'),
        url('/fonts/sofiapro-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'sofia_pro';
    src: url('/fonts/sofiapro-semibold-webfont.woff2')
            format('woff2'),
        url('/fonts/sofiapro-semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'sofia_pro';
    src: url('/fonts/sofiapro-bold-webfont.woff2') format('woff2'),
        url('/fonts/sofiapro-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'sofia_pro';
    src: url('/fonts/sofiapro-black-webfont.woff2') format('woff2'),
        url('/fonts/sofiapro-black-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
