* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(black, 0);
}

html {
    font-size: 62.5%;
    overflow-x: hidden;
}

body {
    @include body-copy;
    color: $color-black;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    background: $color-very-light-pink;

    overflow-x: hidden;

    &.MenuMobileActive {
        overflow: hidden;
    }
}

.highlight {
    color: $color-weird-green;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

a {
    color: $color-black;
}

::selection {
    background-color: $color-weird-green;
    color: $color-black;
}


// From ResultBalls
.BallGreen {
    background-color: $color-weird-green;
}

.BallPink {
    background-color: $color-light-pink;
}

.BallIce {
    background-color: $color-ice;
}

.BallLavender {
    background-color: $color-lavender;
}
